'strict mode';

import $http from '@/services/Api/AxiosInstance';
import { USER_STATES } from '@/services/Utils/State';

const UserRepository = {
  async user(id) {
    let result;
    await $http.get(`v1/users/${id}`)
      .then((res) => { result = res.data.user; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status !== 200) result = err.response;
      });
    return result;
  },
  async users(params = {}, exportExcel = false) {
    let result = {};
    const object = { ...params, ...{ operative: true } };
    const url = !exportExcel ? 'v1/users' : 'v1/exports/users';

    await $http.get(url, { params: object })
      .then((res) => { result = res.data; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async admins(params = {}) {
    let result;
    const joinParams = { ...params, ...{ admin: true } };
    await $http.get('v1/users', { params: joinParams })
      .then((res) => { result = res.data; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async admin(params = {}) {
    let result;
    const joinParams = { ...params };
    await $http.post('v1/admin', joinParams)
      .then((res) => { result = res.data.user; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status !== 200) result = err.response.data;
      });
    return result;
  },
  async enterprises(params = {}) {
    let result;
    const joinParams = { ...params, ...{ onlyEnterprise: true } };
    await $http.get('v1/users', { params: joinParams })
      .then((res) => { result = res.data; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status === 404) result = false;
      });
    return result;
  },
  async enterprise(params = {}) {
    let result;
    const joinParams = { ...params };
    await $http.post('v1/enterprise', joinParams)
      .then((res) => { result = res.data.enterprise; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status !== 200) result = err.response.data;
      });
    return result;
  },
  async activate(id) {
    let result;
    const method = { _method: 'patch' };
    try {
      const response = await $http.post(`v1/users/${id}/activate`, method);
      result = response.data.user;
    } catch (error) {
      result = error.response.data;
    }

    return result;
  },
  async inactive(params, id) {
    let result;
    const object = { ...params, _method: 'patch' };
    try {
      const response = await $http.post(`v1/users/${id}/inactive`, object);
      result = response.data.user;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  },
  async resendValidation(id) {
    let result;
    await $http.post('v1/users/resendValidation', { id })
      .then((res) => { result = res.data.message; })
      .catch((err) => {
        if (err.name === 'Error') result = false;
        if (err.response?.status !== 200) result = false;
      });
    return result;
  },
  async workSchedules(id) {
    let result;

    try {
      const response = await $http.get(`v1/users/${id}/work-schedule`);
      result = response.data;
    } catch (error) {
      result = error.response.data;
    }

    return result;
  },

  // PROFILE

};

export const usersInfoRepo = async (params = {}) => {
  let result;
  const joinParams = {
    ...params, ...{ user_state: USER_STATES.ACTIVO }, ...{ all: true }, ...{ operative: true },
  };
  if (Object.prototype.hasOwnProperty.call(joinParams, 'cancel')) {
    joinParams.cancel.forEach((val) => {
      delete joinParams[val];
    });
    delete joinParams.cancel;
  }
  try {
    const response = await $http.get('v1/users', { params: joinParams });
    result = response.data.users;
  } catch (err) {
    result = err.response;
  }
  return result;
};

export default UserRepository;
