function appendForm(object) {
  return new Promise((resolve) => {
    const formData = new FormData();
    Object.entries(object).forEach((key) => {
      formData.append(key[0], object[key[0]]);
    });
    resolve(formData);
  });
}

export { appendForm };
