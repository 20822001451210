'strict mode';

import $http from '@/services/Api/AxiosInstance';

export const equipmentsToAgreedRepo = async (params = {}) => {
  let result = {};
  const object = { ...params, ...{ recoverable: true } };
  try {
    const response = await $http.get('v1/agreements/equipments', { params: object });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const customersToAgreedRepo = async (params = {}) => {
  let result = {};
  const object = {
    ...params, ...{ recoverable: true }, ...{ qtyIdentificacion: true },
  };
  try {
    const response = await $http.get('v1/agreements/equipments', { params: object });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const optionsRepo = async (params = {}) => {
  let result = {};
  const object = { ...params, ...{ recoverable: true } };
  try {
    const response = await $http.get('v1/agreement/options', { params: object });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const agreementsIndexRepo = async (params = {}, exportExcel = false) => {
  let result = {};
  const object = {
    ...params,
  };
  try {
    const url = !exportExcel ? 'v1/agreements' : 'v1/exports/agreements';
    const response = await $http.get(url, { params: object });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const agreementShowRepo = async (agreement_id, identificacion) => {
  let result = {};
  try {
    const response = await $http.get(`v1/agreement/${agreement_id}/${identificacion}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const equiposGestionadosPactados = async (agreement_id, identificacion) => {
  let result = {};
  try {
    const response = await $http.get(`v1/agreement/equipos/gestionados/${agreement_id}/${identificacion}`);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
}

export const agreementReminderRepo = async (params = {}) => {
  let result = {};
  try {
    const response = await $http.post('v1/agreement/reminder', params);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const agreementSaveRepo = async (params = {}) => {
  let result = {};
  try {
    const response = await $http.post('v1/agreement/agree', params);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const agreementUpdateRepo = async (params = {}) => {
  let result = {};
  try {
    // const object = { ...{ _method: 'patch' }, ...params };
    const response = await $http.put('v1/agreement/agree', params);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const agreementUpdateStateRepo = async (params = {}) => {
  let result = {};
  try {
    const object = { ...{ _method: 'patch' }, ...params };
    const response = await $http.post('v1/agreement/state', object);
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};

export const agreedEquipmentsRepo = async (agree, identificacion, params = {}) => {
  let result = {};
  const object = {
    ...params,
  };
  try {
    const response = await $http.get(`v1/agreement/equipments/${agree}/${identificacion}`, { params: object });
    result = response.data;
  } catch (err) {
    result = err.response.data;
  }
  return result;
};
