import { ref } from 'vue';
import { useStore } from 'vuex';
import $http from '@/services/Api/AxiosInstance';
import { objEmpty } from '@/services/Utils/Objects';

export default () => {
  // Esta funcion mapea los estados del usuario vas utilizados
  // Utiliza el store vuex para ello.

  const store = useStore();

  const areaCodes = ref([]);
  const getAreaCodes = async () => {
    if (store.state.utilities.areaCodes.length === 0) {
      await $http.get('v1/location/areaCodes')
        .then((res) => {
          areaCodes.value = res.data.areaCodes;
          store.dispatch('utilities/setPropUtility', { key: 'areaCodes', value: areaCodes.value });
        })
        .catch(() => {
          console.error('No estas recibiendo los codigos de area');
        });
    } else {
      areaCodes.value = store.state.utilities.areaCodes;
    }

    return areaCodes.value;
  };

  const enterprises = ref([]);
  const getEnterprises = async () => {
    if (store.state.utilities.enterprises.length === 0) {
      await $http.get('v1/enterprisesInfo')
        .then((res) => {
          enterprises.value = res.data.enterprises;
          store.dispatch('utilities/setPropUtility', { key: 'enterprises', value: enterprises.value });
        })
        .catch(() => {
          console.error('No estas recibiendo las empresas');
        });
    } else {
      enterprises.value = store.state.utilities.enterprises;
    }
    return enterprises.value;
  };

  const recommendations = ref([]);
  const getRecommendations = async () => {
    if (store.state.utilities.recommendations.length === 0) {
      await $http.get('v1/recommendations')
        .then((res) => {
          recommendations.value = res.data.recommendations;
          store.dispatch('utilities/setPropUtility', { key: 'recommendations', value: recommendations.value });
        })
        .catch(() => {
          console.error('No estas recibiendo las recomendaciones');
        });
    } else {
      recommendations.value = store.state.utilities.recommendations;
    }
    return recommendations.value;
  };

  const unsubscribed_motives = ref([]);
  const getUnsubscribedMotives = async () => {
    if (store.state.utilities.unsubscribed_motives.length === 0) {
      await $http.get('v1/unsubscribed-motives')
        .then((res) => {
          unsubscribed_motives.value = res.data.unsubscribed_motives;
          store.dispatch('utilities/setPropUtility', { key: 'unsubscribed_motives', value: unsubscribed_motives.value });
        })
        .catch(() => {
          console.error('No estas recibiendo las recomendaciones');
        });
    } else {
      unsubscribed_motives.value = store.state.utilities.unsubscribed_motives;
    }
    return unsubscribed_motives.value;
  };

  const equipment_states = ref([]);
  const getEquipmentStates = async () => {
    if (store.state.utilities.equipment_states.length === 0) {
      await $http.get('v1/equipmentStates')
        .then((res) => {
          equipment_states.value = res.data.equipment_states;
          store.dispatch('utilities/setPropUtility', { key: 'equipment_states', value: equipment_states.value });
        })
        .catch(() => {
          console.error('No estas recibiendo los estados');
        });
    } else {
      equipment_states.value = store.state.utilities.equipment_states;
    }
    return equipment_states.value;
  };

  const agreement_states = ref([]);
  const getAgreementStates = async () => {
    if (store.state.utilities.agreement_states.length === 0) {
      await $http.get('v1/agreementStates')
        .then((res) => {
          agreement_states.value = res.data.agreement_states;
          store.dispatch('utilities/setPropUtility', { key: 'agreement_states', value: agreement_states.value });
        })
        .catch(() => {
          console.error('No estas recibiendo los estados');
        });
    } else {
      agreement_states.value = store.state.utilities.agreement_states;
    }
    return agreement_states.value;
  };

  const agreement_types = ref([]);
  const getAgreementTypes = async () => {
    if (store.state.utilities.agreement_types.length === 0) {
      await $http.get('v1/agreementTypes')
        .then((res) => {
          agreement_types.value = res.data.agreement_types;
          store.dispatch('utilities/setPropUtility', { key: 'agreement_types', value: agreement_types.value });
        })
        .catch(() => {
          console.error('No estas recibiendo los tipos');
        });
    } else {
      agreement_types.value = store.state.utilities.agreement_types;
    }
    return agreement_types.value;
  };

  const contact_channels = ref([]);
  const getContactChannels = async () => {
    if (store.state.utilities.contact_channels.length === 0) {
      await $http.get('v1/contact-channels')
        .then((res) => {
          contact_channels.value = res.data.contact_channels;
          store.dispatch('utilities/setPropUtility', { key: 'contact_channels', value: contact_channels.value });
        })
        .catch(() => {
          console.error('No estas recibiendo los canales');
        });
    } else {
      contact_channels.value = store.state.utilities.contact_channels;
    }
    return contact_channels.value;
  };

  const user = ref([]);
  const getUser = async (force = false) => {
    if (objEmpty(store.state.auth.user) || force) {
      await $http.get('v1/authentication/user')
        .then((res) => {
          user.value = res.data.user;
          store.dispatch('auth/setPropAuth', { key: 'user', value: user.value });
        })
        .catch(() => {
          console.error('No estas recibiendo el usuario');
        });
    } else {
      user.value = store.state.auth.user;
    }
    return user.value;
  };

  const schedules = ref([]);
  const getSchedules = async () => {
    if (store.state.utilities.schedules.length === 0) {
      await $http.get('v1/schedules')
        .then((res) => {
          schedules.value = res.data.schedules;
          store.dispatch('utilities/setPropUtility', { key: 'schedules', value: schedules.value });
        })
        .catch((err) => {
          console.error('No estas recibiendo los canales');
        });
    } else {
      schedules.value = store.state.utilities.schedules;
    }
    return schedules.value;
  };

  return {
    getAreaCodes,
    areaCodes,
    getEnterprises,
    enterprises,
    getRecommendations,
    recommendations,
    getUnsubscribedMotives,
    unsubscribed_motives,
    getEquipmentStates,
    equipment_states,
    getContactChannels,
    contact_channels,
    agreement_states,
    getAgreementStates,
    agreement_types,
    getAgreementTypes,
    getUser,
    user,
    schedules,
    getSchedules
  };
};
