<template>
  <div>
    <div class="main-coupon">
        <div  class="mx-2 flex flex-col   p-4 send-coupon rounded-lg shadow-none sm:shadow-lg">
            <div class="text-black font-bold  text-lg mb-2">
                {{title}}
            </div>
            <div class="mr-2 my-1" style="max-width:25.5rem;">
              <BaseInput
              :placeholder="$t('Email')"
              outline="blue"
              v-model:modelValue="data.email.value"
              :error="data.email.error"
              @error-handle="data.email.error = $event"
              />
            </div>
            <div class="my-2 w-44">
              <BaseButton
              @click="sendEmail()"
              :loading="loading"
              :disabled="loading"
              :text="$t('Enviar por email')"
              />
            </div>
            <div class="my-2 flex flex-row flex-wrap">
              <div class="w-32 mr-4 mt-1 sm:mt-0">
                <BaseSelect
                :placeholder="$t('Codigo Pais')"
                outline="blue"
                :options="areaCodes"
                label="prefix"
                trackBy="prefix"
                v-model="data.prefix.value"
                :error="data.prefix.error"
                @error-handle="data.prefix.error = $event"
                :returnObject="true"
                />
              </div>
              <div class="mt-1 sm:mt-0">
                <BaseInput
                type='number'
                :placeholder="$t('Nro Telefono')"
                outline="blue"
                v-model:modelValue="data.phoneNumber.value"
                :error="data.phoneNumber.error"
                @error-handle="data.phoneNumber.error = $event"
                />
              </div>
            </div>
            <div class="my-2 w-60">
              <BaseButton
              @click="sendWhatsapp()"
              :text="$t('Enviar por whatsapp')"
              :disabled="loading"
              />
            </div>

            <div class="my-2 ">
              <BaseButton
              @click="exit()"
              :text="$t('Salir')"
              />
            </div>
          <slot name="message"></slot>
        </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, watch,
} from 'vue';

// utilididaes
import { AGREEMENT_TYPES } from '@/services/Utils/Utils';
import usualHook from '@/hooks/core/usualHook';
import { objEmpty } from '@/services/Utils/Objects';
//

export default {
  components: {

  },
  props: {
    title: {
      type: String,
      default: 'Enviar coupon',
    },
    email: String,
    prefix: {
      type: [Object, String, Number, Object],
    },
    phoneNumber: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const message = ref('');
    const messageColor = ref('');

    const data = ref({
      email: {
        value: '',
        error: '',
      },
      phoneNumber: {
        value: '',
        error: '',
      },
      prefix: {
        value: {},
        error: '',
      },
    });

    const {
      getAreaCodes, areaCodes,
    } = usualHook();
    getAreaCodes();

    const sendEmail = async () => {
      const send = {
        email: data.value.email.value,
      };
      emit('sendEmail', send);
    };

    const sendWhatsapp = async () => {
      let valid = true;

      if (!data.value.prefix.value || objEmpty(data.value.prefix.value)) {
        data.value.prefix.error = 'Campo requerido';
        valid = false;
      }
      if (!data.value.phoneNumber.value) {
        data.value.phoneNumber.error = 'Campo requerido';
        valid = false;
      }
      if (!valid) {
        return;
      }
      const send = {
        phoneNumber: `${data.value.prefix.value.prefix}${data.value.phoneNumber.value}`,
      };
      emit('sendWhatsapp', send);
    };

    const exit = () => {
      emit('exit');
    };

    onMounted(() => {
      if (props.email) {
        data.value.email.value = props.email;
      }
      if (props.prefix) {
        data.value.prefix.value = props.prefix;
      }
      if (props.phoneNumber) {
        data.value.phoneNumber.value = props.phoneNumber;
      }
    });

    watch(() => props.email, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        data.value.email.value = props.email;
      }
    });
    watch(() => props.prefix, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        data.value.prefix.value = props.prefix;
      }
    });
    watch(() => props.phoneNumber, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        data.value.phoneNumber.value = props.phoneNumber;
      }
    });

    return {
      AGREEMENT_TYPES, data, areaCodes, sendEmail, messageColor, message, exit, sendWhatsapp,
    };
  },
};
</script>

<style>
.main-coupon{

    display: flex;
    align-items: center;

}

@media (min-width:720px){

    .main-coupon{
      height:100%;
      justify-content: center;
    }

}
.send-coupon{
    height: 70vh;
}

</style>
