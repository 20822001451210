<template>
  <div class="continuous-1"></div>
</template>

<script>
export default {

};
</script>

<style>
.continuous-1 {
  width: 100%;
  height:4px;
  background:
    linear-gradient(90deg,#0001 33%,#0005 50%,#0001 66%)
    #f2f2f2;
  background-size:300% 100%;
  animation: ct1 1s infinite linear;
}

@keyframes ct1 {
  0% {background-position: right}
}

</style>
