export const reverseStr = (str, separator = '-') => {
  if (str) {
    return str.split(separator).reverse().join(separator);
  }
  return str;
};

export const formatDateTime = (dateTime) => {
  if (!dateTime) return '';
  const date = new Date(dateTime);
  const dia = date.getDate();

  const mes = date.getMonth() + 1;
  const ano = date.getFullYear();
  const hora = date.getHours();
  const minutos = date.getMinutes();
  if (!dia || !ano) return dateTime;
  return (`${dia}/${mes}/${ano} ${(`0${hora}`).slice(-2)}:${(`0${minutos}`).slice(-2)}`);
};

export const formatDate = (onlyDate) => {
  const date = new Date(onlyDate);

  const dia = date.getDate();
  const mes = date.getMonth() + 1;
  const ano = date.getFullYear();

  return (`${dia}/${mes}/${ano}}`);
};

export const changeFormatDate = (fecha) => {
  if (!fecha) return '-';
  const date = new Date(fecha);
  const dia = (`0${date.getDate()}`).slice(-2);
  const mes = (`0${date.getMonth() + 1}`).slice(-2);
  const ano = date.getFullYear();
  const hora = date.getHours();
  const minutos = date.getMinutes();

  return (`${dia}/${mes}/${ano} ${(`0${hora}`).slice(-2)}:${(`0${minutos}`).slice(-2)}`);
};

export function getDateTime(time = true) {
  const today = new Date();
  let getMin;
  let getSeconds;
  let getHours;
  let dateFormat;
  if (time) {
    getMin = today.getMinutes();
    getSeconds = today.getSeconds();
    getHours = today.getHours();
    if (getMin < 10) { getMin = `0${today.getMinutes()}`; }
    if (getSeconds < 10) { getSeconds = `0${today.getSeconds()}`; }
    if (getHours < 10) { getHours = `0${today.getHours()}`; }
    dateFormat = `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${
      (`0${today.getDate()}`).slice(-2)} ${getHours}:${getMin}:${getSeconds}`;
  } else {
    dateFormat = `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${
      (`0${today.getDate()}`).slice(-2)}`;
  }

  return dateFormat;
}

export function getDateDay() {
  const today = new Date();
  return today.getDay();
}

export function translateDay(day) {
  if (day === 0) {
    return 'Lunes';
  } if (day === 1) {
    return 'Martes';
  } if (day === 2) {
    return 'Miercoles';
  } if (day === 3) {
    return 'Jueves';
  } if (day === 4) {
    return 'Viernes';
  } if (day === 5) {
    return 'Sabado';
  } if (day === 6) {
    return 'Domingo';
  }
  return 'Dia';
}

export const typeDay = (data, today = new Date()) => {
// por lo menos debe tener el parametro created_at_day
  if (!data.created_at_day) {
    console.error('La propiedad created_at_day, es obligatoria');
    return false;
  }
  let value;
  const d1 = new Date(data.created_at_day);
  const diff = Math.abs(today.getTime() - d1.getTime());
  const daydiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
  if (daydiff <= 7) {
    if (daydiff === 1) {
      value = 'Hoy';
    } else if (!data.created_at_translate) {
      value = translateDay(d1.getDay());
    } else {
      value = data.created_at_translate;
    }
  } else {
    value = reverseStr(data.created_at_day);
  }
  return value;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
